<template>
  <div v-if="currentUser">
    <v-container>
      <h1>Admin Panel</h1>
      <v-tabs
        class="mt-8"
        background-color="transparent"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        center-active
      >
        <v-tab ripple>Admins</v-tab>
        <v-tab ripple>Partners</v-tab>

        <v-tab-item>
          <Admins :user="currentUser" />
        </v-tab-item>
        <v-tab-item>
          <Partners :user="currentUser" />
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>
<script>
import ProxyLayout from '@/layouts/ProxyLayout'
import Admins from '@/components/admin/Admins'
import Partners from '@/components/admin/Partners'
export default {
  name: 'Admin',
  props: ['user'],
  components: {
    Admins,
    Partners
  },
  data() {
    return {
      currentUser: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
          if (
            this.currentUser.role.toLowerCase() == 'doctor' ||
            this.currentUser.role.toLowerCase() == 'nurse' ||
            this.currentUser.role.toLowerCase() == 'staff'
          ) {
            this.$emit(`update:layout`, ProxyLayout)
          }
        }
      }
    }
  }
}
</script>
