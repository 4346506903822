<template>
  <div class="mt-4">
    <v-card>
      <v-card-title>
        Partners
        <v-btn
          class="ml-4"
          @click="addPartnerDialog = true"
          icon
          dark
          small
          color="primary"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="partners"
          item-key="index"
          class="elevation-0"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn text color="error" @click="removePartner(item)"
              >Remove</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="addPartnerDialog"
      v-model="addPartnerDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>Add Partner</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="form.name"
              name="name"
              label="Name"
              type="text"
              :rules="[rules.required]"
              required
              outlined
              rounded
              dense
            />
            <v-text-field
              v-model="form.email"
              label="Email"
              dense
              outlined
              rounded
              required
              :rules="emailRules"
            ></v-text-field>

            <v-text-field
              v-model="form.contact"
              name="contact"
              label="Contact Name (optional)"
              type="text"
              outlined
              rounded
              dense
            />
            <v-text-field
              v-model="form.phoneNumber"
              name="phoneNumber"
              label="Phone Number (optional)"
              type="text"
              :rules="phoneRules"
              required
              outlined
              rounded
              dense
            />

            <v-text-field
              v-model="form.address"
              name="address"
              label="Address (optional)"
              type="text"
              outlined
              rounded
              dense
            />

            <v-alert dense type="error" dismissible v-if="error">
              {{ error }}
            </v-alert>

            <div class="d-flex justify-end">
              <v-btn
                :disabled="!valid"
                :block="$mq === 'mobile'"
                color="primary"
                :loading="loading"
                @click="addPartner"
                >Add Partner</v-btn
              >
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedPartner"
      v-model="removePartnerDialog"
      :key="selectedPartner.id"
      max-width="400"
    >
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text>
          Are you sure you would like to remove the partner
          {{ selectedPartner.name }}?
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn
                color="primary"
                :loading="loadingDelete"
                outlined
                block
                @click="deletePartner()"
                >Yes</v-btn
              >
            </v-col>
            <v-col cols="12" md="6">
              <v-btn color="error" outlined block @click="closeDelete()"
                >No</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as fb from '@/firebase'
export default {
  name: 'Partners',
  props: ['user'],
  data() {
    return {
      currentUser: null,
      password: '',
      rules: {
        required: value => !!value || 'Required.'
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      phoneRules: [
        value => !!value || !value,
        value => {
          const pattern = /^\d{3}-\d{3}-\d{4}$/
          const pattern2 = /^\d{10}$/
          const pattern3 = /^\s*$/
          return (
            pattern.test(value) ||
            pattern2.test(value) ||
            pattern3.test(value) ||
            'Invalid phone number'
          )
        }
      ],
      error: '',
      valid: false,
      loading: false,
      form: {
        name: '',
        email: '',
        contact: '',
        phoneNumber: '',
        address: ''
      },
      partners: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email'
        },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      loadingDelete: false,
      addPartnerDialog: false,
      removePartnerDialog: false,
      selectedPartner: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
        }
      }
    }
  },
  created() {
    this.$bind('partners', fb.db.collection(`Partners`))
  },
  methods: {
    async addPartner() {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.password = this.generatePassword()
        const registerPartner = fb.functions.httpsCallable('registerPartner')
        let available = await this.checkExists()
        if (available) {
          try {
            registerPartner({
              name: this.form.name,
              email: this.form.email,
              contact: this.form.contact,
              address: this.form.address,
              phoneNumber: this.form.phoneNumber,
              code: this.password
            })
            this.$refs.form.reset()
            this.addPartnerDialog = false
          } catch (error) {
            this.error = error.message
            this.loading = false
          }
        } else {
          this.error = 'This partner already has an account.'
          this.loading = false
        }
      } else {
        this.error = 'All Fields Required'
      }
      this.loading = false
    },
    async checkExists() {
      const partnerQuery = await fb.db
        .collection('Partners')
        .where('email', '==', this.form.email)
        .get()
      let available = false
      if (partnerQuery.empty) {
        available = true
      }
      return available
    },
    generatePassword() {
      const length = 12
      var result = ''
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    removePartner(item) {
      this.selectedPartner = item
      this.removePartnerDialog = true
    },
    closeDelete() {
      this.removePartnerDialog = false
      this.selectedPartner = null
    },
    async deletePartner() {
      this.loadingDelete = true
      const deletePartner = fb.functions.httpsCallable('deletePartner')
      try {
        await deletePartner({
          partnerId: this.selectedPartner.id
        })
        this.loadingDelete = false
        this.selectedPartner = null
        this.removePartnerDialog = false
      } catch (error) {
        this.error = error.message
        this.loadingDelete = false
      }
    }
  }
}
</script>
