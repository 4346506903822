<template>
  <div class="mt-4">
    <v-card>
      <v-card-title>
        Admins
        <v-btn
          class="ml-4"
          @click="addAdminDialog = true"
          icon
          dark
          small
          color="primary"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="admins"
          item-key="index"
          class="elevation-0"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn text color="error" @click="removeAdmin(item)">Remove</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addAdminDialog" max-width="500">
      <v-card>
        <v-card-title>Add Admin</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-autocomplete
              v-model="form.email"
              :items="proxies"
              label="Select User"
              item-text="nameEmail"
              item-value="email"
              :rules="rules"
              return-object
              outlined
              rounded
              dense
            ></v-autocomplete>
            <!--          
            <v-text-field
              v-model="form.email"
              name="email"
              label="User Email"
              type="text"
              :rules="emailRules"
              outlined
              rounded
              dense
            /> -->
          </v-form>
          <v-btn
            :disabled="!valid"
            :loading="loading"
            color="primary"
            @click="addAdmin"
            >Add Admin</v-btn
          >
          <v-alert dense type="error" dismissible v-if="error">
            {{ error }}</v-alert
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedAdmin"
      v-model="removeAdminDialog"
      :key="selectedAdmin.id"
      max-width="400"
    >
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-card-text>
          Are you sure you would like to remove admin privilages for
          {{ selectedAdmin.email }}?
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-btn color="primary" outlined block @click="deleteAdmin()"
                >Yes</v-btn
              >
            </v-col>
            <v-col cols="12" md="6">
              <v-btn color="error" outlined block @click="closeDelete()"
                >No</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as fb from '@/firebase'
export default {
  name: 'Admins',
  props: ['user'],
  data() {
    return {
      currentUser: null,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      rules: [v => !!v || 'Required'],
      error: '',
      valid: false,
      loading: false,
      form: {
        email: null
      },
      admins: [],
      headers: [
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email'
        },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      proxiesCollection: [],
      proxies: [],
      addAdminDialog: false,
      removeAdminDialog: false,
      selectedAdmin: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
        }
      }
    },
    admins: {
      immediate: true,
      handler() {
        this.proxies = []
        for (const item of this.proxiesCollection) {
          let index = this.admins.findIndex(x => x.id == item.id)
          if (index == -1) {
            this.proxies.push({
              id: item.id,
              nameEmail: `${item.displayName} - ${item.email}`,
              ...item
            })
          }
        }
      }
    },
    proxiesCollection: {
      immediate: true,
      handler() {
        this.proxies = []
        for (const item of this.proxiesCollection) {
          let index = this.admins.findIndex(x => x.id == item.id)
          if (index == -1) {
            this.proxies.push({
              id: item.id,
              nameEmail: `${item.displayName} - ${item.email}`,
              ...item
            })
          }
        }
      }
    }
  },
  async created() {
    await this.$bind(
      'admins',
      fb.db.collection(`Admins`).where('email', '!=', this.currentUser.email)
    )
    await this.$bind(
      'proxiesCollection',
      fb.db.collection('Proxies').where('email', '!=', this.currentUser.email)
    )
  },
  methods: {
    async addAdmin() {
      this.loading = true
      if (this.$refs.form.validate()) {
        const addAdmin = fb.functions.httpsCallable('addAdmin')
        try {
          await addAdmin({
            email: this.form.email.email
          })
          this.loading = false
          this.$refs.form.reset()
          this.addAdminDialog = false
        } catch (error) {
          this.error = error.message
          this.loading = false
        }
      }
      this.loading = false
    },
    removeAdmin(item) {
      this.selectedAdmin = item
      this.removeAdminDialog = true
    },
    closeDelete() {
      this.removeAdminDialog = false
      this.selectedAdmin = null
    },
    async deleteAdmin() {
      this.loading = true
      try {
        await fb.db.doc(`Admins/${this.selectedAdmin.id}`).delete()
        this.loading = false
        this.selectedAdmin = null
        this.removeAdminDialog = false
      } catch (error) {
        this.error = error.message
        this.loading = false
      }
    }
  }
}
</script>
